import React, { Component } from "react";

import "react-vertical-timeline-component/style.min.css";

class Edu extends Component {
  render() {
    // var sectionName = this.props.resumeBasicInfo.section_name.experience;

    if (this.props.resumeEdu && this.props.resumeBasicInfo) {
      var sectionName = this.props.resumeBasicInfo.section_name.edu;
      var edu = this.props.resumeEdu;
    }

    return (
      <section id="edu" className="pb-5">
        <div className="col-md-12 mx-auto">
          <div className="col-md-12">
            <h1 className="section-title" style={{ color: "black" }}>
              <span className="text-black" style={{ textAlign: "center" }}>
                {sectionName}
              </span>
            </h1>
          </div>
        </div>
        <div className="col-md-8 mx-auto center">
          <div className="center eduCard">
            <div className="iconEdu">
              {" "}
              <i className="fab fa fa-graduation-cap mx-auto"></i>
            </div>
            <div className="tilteEdu">{`${edu?.degree} ${edu?.in} ${edu?.graduation}`}</div>
            <div className="textEdu">
              {`${edu?.uni}, ${edu?.description} ${edu?.year}`}
             </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Edu;
