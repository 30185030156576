import React, { Component } from "react";
import "react-vertical-timeline-component/style.min.css";

class ContactUs extends Component {
  constructor(props) {
    super();
    this.state = {
      msgOBJ: {
        msg: "",
        showError: false,
        showSuccess: false,
      },
      data: {
        name: "",
        email: "",
        message: "",
      },
    };
  }

  submitForm = () => {
    this.setState({
      msgOBJ: {
        msg: "",
        showError: false,
        showSuccess: false,
      },
    });
    var data = this.state.data;
    let valid = true;
    if (data.email === "" || data.name === "" || data.message === "") {
      valid = false;
      this.setState({
        msgOBJ: {
          msg: "Please fill all the fields",
          showError: true,
          showSuccess: false,
        },
      });
    } else {
      if (!data.email.includes("@") || !data.email.includes(".")) {
        valid = false;
        this.setState({
          msgOBJ: {
            msg: "Please enter a valid email",
            showError: true,
            showSuccess: false,
          },
        });
      }
      else if(data.message.length < 10){
        valid = false;
        this.setState({
          msgOBJ: {
            msg: "Please enter a valid message",
            showError: true,
            showSuccess: false,
          },
        });
      }
      else if(data.name.length < 3){
        valid = false;
        this.setState({
          msgOBJ: {
            msg: "Please enter a valid name",
            showError: true,
            showSuccess: false,
          },
        });
      }
      if(valid){
        this.setState({
          msgOBJ: {
            msg: "",
            showError: false,
            showSuccess: true,
          },
          data:{
            name: "",
            email: "",
            message: "",
          }
        });
      }
    }
  };
  handleChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  };
  render() {
    if (this.props.resumeData && this.props.resumeBasicInfo) {
      var sectionName = this.props.resumeBasicInfo.section_name.ContactUs;
      var resumeData = this.props.resumeData.form;
    }

    return (
      <section id="contact" className="pb-5">
        <div className="col-md-12 mx-auto">
          <div className="col-md-12">
            <h1 className="section-title" style={{ color: "black" }}>
              <span className="text-white " style={{ textAlign: "center" }}>
                {sectionName}
              </span>
            </h1>
          </div>
        </div>
        <div className="col-md-6 mx-auto">
          <div
            id="sendmessage"
            className={this.state.msgOBJ.showSuccess ? "show" : "hide"}
          >
            {resumeData?.sendmessage}
          </div>

          <div
            id="errormessage"
            className={this.state.msgOBJ.showError ? "show" : "hide"}
          >
            {this.state.msgOBJ.msg}
          </div>
          <form>
            <div className="form-group">
              <input
                type="name"
                name="name"
                className="form-control"
                id="exampleFormControlInput1"
                placeholder={resumeData?.name}
                onChange={this.handleChange}
                value={this.state.data.name}
              />
            </div>
            <div className="form-group">
              <input
                type="email"
                name="email"
                className="form-control"
                id="exampleFormControlInput1"
                placeholder={resumeData?.email}
                onChange={this.handleChange}
                value={this.state.data.email}
              />
            </div>
            <div className="form-group">
              <textarea
                className="form-control"
                id="message"
                name="message"
                rows="3"
                placeholder={resumeData?.message}
                onChange={this.handleChange}
                value={this.state.data.message}
              ></textarea>
            </div>
            <div className="form-group">
              <button
                type="button"
                className="btn btn-primary"
                onClick={this.submitForm}
              >
                {resumeData?.send}
              </button>
            </div>
          </form>
        </div>
      </section>
    );
  }
}

export default ContactUs;
