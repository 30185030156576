import React from "react";
import { slide as Menu } from "react-burger-menu";

export default (props) => {
  return (
    <div className="burger-menu">
      {" "}
      <Menu {...props} right={document.documentElement.lang === 'ar'}>
        <a className="menu-item" href="#about">
          {props.resumeBasicInfo?.section_name?.about}
        </a>

        <a className="menu-item" href="#portfolio">
          {props.resumeBasicInfo?.section_name?.projects}
        </a>

        <a className="menu-item" href="#skills">
          {props.resumeBasicInfo?.section_name?.skills}
        </a>

        <a className="menu-item" href="#resume">
          {props.resumeBasicInfo?.section_name?.experience}
        </a>
        <a className="menu-item" href="#edu">
          {props.resumeBasicInfo?.section_name?.edu}
        </a>
        <a className="menu-item" href="#contact">
          {props.resumeBasicInfo?.section_name?.ContactUs}
        </a>
      </Menu>
    </div>
  );
};
